import React from "react";
import { Box, Card, Heading, Image, Text } from "theme-ui";
import { Event } from '@mui/icons-material'
import ReactMarkdown from "react-markdown";
import { Helmet } from 'react-helmet';
import AuthenticatedDataView from "../../components/AuthenticatedDataView";
import Layout from "../../components/Layout";
import {endpoint} from "../../lib/endpoint";
import ActionButton from "../../components/ActionButton";
import LinkInApp from "../../components/LinkInApp";
import { jsonToEvent } from "../../types";
import {navigate} from "gatsby";
import EventDateAndTime from "../../components/EventDateAndTime";

type EventParams = {
  params: {
    id: string
  }
}

type EventMetaData = {
  isLoggedIn: boolean,
  isEventOwner: boolean,
  isRegistered: boolean
};

type EventData = {
  event: {
    id: number,
    title: string,
    description: string,
    guid: string,
    image_card_url?: string,
  },
  meta: EventMetaData
};

type EventOnCardProps = {
  data: EventData
};

const EventOnCard = ({ data }: EventOnCardProps) => {

  const { meta } = data;
  const event = jsonToEvent(data.event);

  if (!event.title) {
    return <Card><Text>There is no such event. Oops.</Text></Card>;
  }

  const { isRegistered, isLoggedIn, isEventOwner } = meta;

  return (
    <>
      <Helmet title={event.title} meta={[
        {
          name: 'description',
          content: `Mindful Event: ${event.description}`
        },
        {
          property: 'og:title',
          content: event.title
        },
        {
          property: 'og:description',
          content: event.description
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          name: 'twitter:card',
          content: 'summary'
        },
        {
          name: 'twitter.title',
          content: event.title
        },
        {
          name: 'twitter.description',
          content: event.description
        }
      ]}/>
      <Card>
        { event.image_card_url &&
          <Box className="event-image">
            <Image src={event.image_card_url} variant="eventCard" />
          </Box>
        }
        <Heading sx={{ pt: 2 }}>
          <Box sx={{ display: 'inline', pr: 2 }}>
            <Event />
          </Box>
          {event.title}
        </Heading>
        <EventDateAndTime event={event} />
        <Text>
          <ReactMarkdown>
            {event.description}
          </ReactMarkdown>
        </Text>
        { !isRegistered &&
          <>
            <ActionButton isValid={true} onClick={() => {
              navigate(`/registration-confirmations/${event.guid}`);
            }}>
              Register
            </ActionButton>
            <Box>
              <Text>
                Registration takes just 30 seconds! The organizer can then provide you with details and updates.
              </Text>
            </Box>
          </>
        }
        { !isLoggedIn && 
          <Box>
            <Text>Registered already?</Text>
          </Box>
        }
        { isLoggedIn && isRegistered &&
          <Box>
            <Text>
              You are registered for this event.
              &nbsp;
              <LinkInApp to={`/registration-cancellations/${event.guid}`}>Cancel</LinkInApp> your registration.
            </Text>
          </Box>
        }
        { isEventOwner &&
          <Box>
            <hr/>
            <Text>
              This is your own event. 
            </Text>
            <Text pl={2}>
              <LinkInApp to={`/owned-events/edit/${event.id}`}>Edit</LinkInApp> this event.
            </Text>
          </Box>
        }
      </Card>
    </>
  );
}

export default ({ params }: EventParams) => {
  console.log('render event, params', params);
  const { id } = params;
  return (
    <Layout>
      <AuthenticatedDataView fetchUrl={`${endpoint}/events/${id}`}
        render={data => <>
          <EventOnCard data={data as EventData} />
          {/* <h1>Data</h1>
          <p>{JSON.stringify(data)}</p> */}
        </>}
      />
    </Layout>
  )
}
